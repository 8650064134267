<template>

  <div class="range"> 
    <div class="lb">
      <div class="lf">
        {{ props.leftLabel }}: 
        {{ edited_value }} {{ props.unit }}
      </div> 
      <div class="rh">
        {{ props.rightLabel }}: 
        {{ props.max - edited_value }} {{ props.unit }}
      </div>
    </div>
    <input
      class="rg"
      type="range"
      :min="props.min"
      :max="props.max"
      :step="props.step"
      v-model="edited_value"
      @change="change"
      />
  </div>

</template>

<script setup>

const emits = defineEmits(['change:vl'])

const props = defineProps({
  unit: {
    type: String,
    default: ''
  },
  leftLabel: {
    type: String,
    default: ''
  },
  rightLabel: {
    type: String,
    default: ''
  },
  vl: {
    type: Number,
    default: 0
  },
  min: {
    type: Number,
    default: 0
  },
  max: {
    type: Number,
    default: 0
  },
  step: {
    type: Number,
    default: 0
  },
  editable: {
    type: Boolean,
    default: true
  }
})
 
var edited_value = ref(props.vl);


watch(() => props.vl, (val) => {
  edited_value.value = val
})

const change = () => {
  console.log(edited_value.value);
  if(props.editable){
    emits('change:vl', edited_value.value)
  }
}

</script>

<style scoped>
 
.range{}
.range .lf{}
.range .rh{}
.range .rg{}
.range .lb{    
  display: flex;
  justify-content: space-between;
}


/* stule input */
 
 input[type=range] {
  height: 25px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: var(--gd-range-back-color);
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid var(--gd-range-back-color);
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: var(--gd-range-back-color-light);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: var(--gd-range-back-color);
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: var(--gd-range-back-color);
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid var(--gd-range-back-color);
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: var(--gd-range-back-color-light);
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: var(--gd-range-back-color);
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-fill-upper {
  background: var(--gd-range-back-color);
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid var(--gd-range-back-color);
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: var(--gd-range-back-color-light);
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: var(--gd-range-back-color);
}
input[type=range]:focus::-ms-fill-upper {
  background: var(--gd-range-back-color);
}


</style>
