<template>
  <div class="" v-if="edited_week">    

    <div class="divider">{{ $t('create_week_view_navigation_title') }}</div>

     
    <DiaryEditProgress
      :steps="progress_steps"
      :states="progress_states"
      />

    <div class="divider" ref="elWeekStrain">{{ $t('create_harvest_view_strain_title') }}</div>
  
    <WeekEditSeedReviewHarvest 
      :data="edited_week" 
      :is-smoke="true"
      /> 

    <div class="divider" ref="elWeekResult">{{ $t('create_harvest_view_outcome_title') }}</div>

    <div class="parameters">
  
    <WeekEditParamNumber       
      :num="edited_week.props.harvest_weight"
      :ico="'setup_ico_n_weight'"
      :label="$t('create_harvest_view_outcome_title')"
      :param="$t('create_harvest_view_outcome_dry')"
      :measure="useAuth().getWeight()"
      :units="[{id: 'english', name: 'ounce'}, {id: 'metric', name: 'gram'}]"
      :is-presaved="edited_week.is_presaved"
      @update:num="edited_week.props.harvest_weight = $event"
      @change_measure="useAuth().setWeight($event)"
      />
 
 

    </div>


    <div class="divider" ref="elWeekPhotos">{{ $t('photo_video_manager_external_title') }}</div>    

    <WeekEditWarningPhotos/>

    <div class="udr_bx">
      <UiUploader
        :enable-drag-drop="!edited_week.items_photo?.filter((opt) => opt.is_smoke == 1).length"
        @changed="changePhotos" 
        @uploaded="uploadedPhotos" 
        @progress="uploadingPhotos" 
        @errored="erroredPhotos"
      />
    </div>
    



 
    <draggable 
      v-model="edited_week.items_photo" 
      item-key="id" 
      v-if="edited_week.items_photo" 
      @start="drag=true"  
      @end="drag=false" 
      class="photos"
      delay-on-touch-only="250"
      delay="250"
      animation="200"
        ghost-class="photos_ghost"
      >

        <template #item="{element}">
          <WeekEditPhoto
            :item="element"
            v-show="element?.is_remove != 1"
            @remove="removePhoto(element.id)"
            @update:item="element = $event"
            @rotate="saveRotate"
            />
        </template>


    </draggable>
     

    <!-- <VueDraggable 
        v-if="edited_week.items_photo" 
        @start="drag=true"  
        @end="drag=false" 
        delay-on-touch-only="250"
        delay="250"
        animation="200"
        class="photos"
        ghost-class="photos_ghost"
        v-model="edited_week.items_photo" 
        item-key="id" 
        > 


          <WeekEditPhoto
            v-for="(element,element_key) in edited_week.items_photo?.filter((opt) => opt.is_smoke == 1)"
            :item="element"
            v-show="element?.is_remove != 1"
            @remove="removePhoto(element.id)"
            @update:item="element = $event"
            @rotate="saveRotate"
            />


      </VueDraggable> -->
  
      <div class="divider">{{ $t('create_harvest_view_feels_subtitle') }}</div>    



        <div class="slid">
          <UiRange
            class="rg"
            type="range"
            :min="0"
            :max="100"
            :step="1"
            :unit="'%'"
            :left-label="t('create_harvest_view_feels_indica')"
            :right-label="t('create_harvest_view_feels_sativa')"
            :vl="edited_week.item_harvest.item_review_seed.props.indica"
            @change:vl="edited_week.item_harvest.item_review_seed.props.indica = $event"
            />
        </div>

      <div class="divider">{{ $t('create_harvest_view_tastes_title') }}</div>    
  
 
      <WeekEditEffectTaste
        :selected="computedTaste"
        :list="$constants.taste"
        :max="3"
        :image-path="'/images/taste/'"
        @select="selectTaste"
        />


      <div class="divider">{{ $t('create_harvest_view_feels_positive') }}</div>    


      <WeekEditEffectTaste
        :selected="computedPositive"
        :list="$constants.effectPositive"
        :max="3"
        :image-path="'/images/effect/'"
        @select="selectEffectTaste('items_effect_positive', $event)"
        />

      <div class="divider">{{ $t('create_harvest_view_feels_negative') }}</div>    

      <WeekEditEffectTaste
        :selected="computedNegative"
        :list="$constants.effectNegative"
        :max="3"
        :image-path="'/images/effect/'"
        @select="selectEffectTaste('items_effect_negative', $event)"
        />

      <div class="divider">{{ $t('create_harvest_view_feels_medical') }}</div>    

      <WeekEditEffectTaste
        :selected="computedMedical"
        :list="$constants.effectMedical"
        :max="3"
        :image-path="'/images/effect/medical/'"
        @select="selectEffectTaste('items_effect_medical', $event)"
        />


    <div class="divider" ref="elWeekComment">{{ $t('create_week_view_owner_comment_title') }}</div>    

    <UiTextArea 
      :text="edited_week.item_harvest.item_review_seed.props.smoke_comment"
      @change:text="edited_week.item_harvest.item_review_seed.props.smoke_comment = $event"
      />
 

    <UiButtons>
      <UiButton type="primary" :is-loading="props.isLoadingSave" :name="$t('universal_button_save')" @click="save"/>      
      <UiButton :name="$t('universal_button_cancel')" @click="cancel"/>              
    </UiButtons>


  </div>
</template>



<script setup>

import draggable from 'vuedraggable'

const route = useRoute()
const router = useRouter()
const { $progressBarContent, $api, $popup } = useNuxtApp()
const { t } = useI18n()
const emits = defineEmits(['saveweek', 'canceleditweek'])
const elWeekStrain = ref(null)
const elWeekPhotos = ref(null)
const elWeekResult = ref(null)
const elWeekComment = ref(null)

const props = defineProps({
  week: {
    type: Object,
    required: true,
  },
  diary: {
    type: Object,
    required: true,
  },
  isLoadingSave: {
    type: Boolean,
    required: false,
    default: false,
  },
})

var is_loaded = ref(false)
var link = ref(route.params['week'])
var edited_week = ref(props.week)

var progress_steps = ref([
  {
    id: 'strain',
    name: t('create_harvest_view_navigation_strain'),
    hint: t('create_week_view_navigation_good_to_grow'),
    el: elWeekStrain,
  },
  {
    id: 'result',
    name: t('create_harvest_view_navigation_outcome'),
    hintGood: t('create_harvest_view_navigation_outcome_description1'),
    hintNormal: t('create_harvest_view_navigation_outcome_description2'),
    hintBad: t('create_harvest_view_navigation_outcome_description3'),
    el: elWeekResult,
  },
  {
    id: 'photos',
    name: t('create_week_view_navigation_photos_videos'),
    hintGood: t('create_week_view_navigation_now_talking'),
    hintNormal: t('create_week_view_navigation_more_photos_videos'),
    hintBad: t('create_week_view_navigation_some_photos_videos'),
    el: elWeekPhotos,
  }, 
  {
    id: 'comment',
    name: t('create_week_view_navigation_comment'),
    hintGood: t('create_week_view_navigation_cool_info'),
    hintNormal: t('create_week_view_navigation_wanna_tell'),
    hintBad: t('create_week_view_navigation_share_ideas'),
    el: elWeekComment,
  },
])

const progress_states = computed(() => {
  // console.log('$progressBarContent');
  // console.log($progressBarContent.calcWeekVeg);

  var ret = $progressBarContent.calcWeekHar(edited_week.value);

  return ret;
})


const computedTaste = computed(() => { 
  return edited_week.value.item_harvest.item_review_seed.items_taste?.map(item => item.id);
})
const computedPositive = computed(() => { 
  return edited_week.value.item_harvest.item_review_seed.items_effect_positive?.map(item => item.id);
})
const computedNegative = computed(() => { 
  return edited_week.value.item_harvest.item_review_seed.items_effect_negative?.map(item => item.id);
})
const computedMedical = computed(() => { 
  return edited_week.value.item_harvest.item_review_seed.items_effect_medical?.map(item => item.id);
})



const changePhotos = function(uploading_id, thumbnail){
  edited_week.value.items_photo = edited_week.value.items_photo || [];
  edited_week.value.items_photo.push({
    is_loading: true, 
    uploading_id: uploading_id, 
    thumbnail: thumbnail,
    is_smoke: 1,
  });
}

const findReplaceUploadingPhoto = function(uploading_id, item){
  edited_week.value.items_photo.forEach((opt, opt_key) => {
    if(opt.uploading_id == uploading_id){
      edited_week.value.items_photo[opt_key] = item; 
    }
  });
}

const findReplaceUploadingPhotoProgress = function(uploading_id, progress){
  edited_week.value.items_photo.forEach((opt, opt_key) => {
    if(opt.uploading_id == uploading_id){
      edited_week.value.items_photo[opt_key]['progress'] = progress; 
    }
  });
}
const uploadingPhotos = function(progress, uploading_id){      
  edited_week.value.items_photo = edited_week.value.items_photo || [];
  findReplaceUploadingPhotoProgress(uploading_id, progress);
}
const uploadedPhotos = function(item, uploading_id){      
  edited_week.value.items_photo = edited_week.value.items_photo || [];
  item.is_smoke = 1;
  // edited_week.value.items_photo.push(item);   
  findReplaceUploadingPhoto(uploading_id, item);
}

const erroredPhotos = function(uploading_id){      
  $popup.error('Error uploading file');
  removePhoto(null, uploading_id);
}

const removePhoto = function(id, uploading_id){            
  edited_week.value.items_photo.forEach((opt, opt_key) => {
    if(opt.id && opt.id == id){
      edited_week.value.items_photo[opt_key].is_remove = 1; 
    }else if(opt.uploading_id && opt.uploading_id == uploading_id) {
      edited_week.value.items_photo[opt_key].is_remove = 1; 
    }
  });
}

const selectEffectTaste = function(type, key){
  edited_week.value.item_harvest.item_review_seed[type] = edited_week.value.item_harvest.item_review_seed[type] || [];
  if(!edited_week.value.item_harvest.item_review_seed[type].find((opt) => opt.id == key))
    edited_week.value.item_harvest.item_review_seed[type].push({
      id: Number(key),
      name: '',
    });
  else
    edited_week.value.item_harvest.item_review_seed[type] = edited_week.value.item_harvest.item_review_seed[type].filter((opt) => opt.id != key);
}

const selectEffect = function(key){
  edited_week.value.item_harvest.item_review_seed.items_effect = edited_week.value.item_harvest.item_review_seed.items_effect || [];
  if(!edited_week.value.item_harvest.item_review_seed.items_effect.find((opt) => opt.id == key))
    edited_week.value.item_harvest.item_review_seed.items_effect.push({
      id: Number(key),
      name: '',
    });
  else
    edited_week.value.item_harvest.item_review_seed.items_effect = edited_week.value.item_harvest.item_review_seed.items_effect.filter((opt) => opt.id != key);
}

const selectTaste = function(key){
  edited_week.value.item_harvest.item_review_seed.items_taste = edited_week.value.item_harvest.item_review_seed.items_taste || [];
  if(!edited_week.value.item_harvest.item_review_seed.items_taste.find((opt) => opt.id == key))
    edited_week.value.item_harvest.item_review_seed.items_taste.push({
      id: Number(key),
      name: '',
    });
  else
    edited_week.value.item_harvest.item_review_seed.items_taste = edited_week.value.item_harvest.item_review_seed.items_taste.filter((opt) => opt.id != key);
}


const cancel = function(){  
  emits('canceleditweek', edited_week.value.id)
}

const save = function(){       
  edited_week.value.is_smoke = 1;
  emits('saveweek', edited_week.value)
}


const setPhotoLoading = function(id, state){
  edited_week.value.items_photo.forEach((opt, opt_key) => {
    if(opt.id == id){
      console.log('is_loading photo' + id + ' state: ' + state);
      edited_week.value.items_photo[opt_key].is_loading = state;
    }
  });
}

const setPhoto = function(id, item){
  console.log('setPhoto photo' + id + ' item: ' + item.size_m);
  edited_week.value.items_photo.forEach((opt, opt_key) => {
    if(opt.id == id){
      edited_week.value.items_photo[opt_key].size_xl = item.size_xl;
      edited_week.value.items_photo[opt_key].size_l = item.size_l;
      edited_week.value.items_photo[opt_key].size_m = item.size_m;
      edited_week.value.items_photo[opt_key].size_s = item.size_s;
      edited_week.value.items_photo[opt_key].size_xs = item.size_xs;
    }
  });
}

const saveRotate = function(item, rotate_value){

  setPhotoLoading(item.id, true);

  $api.postRotatePhoto(item.id, rotate_value)
  .then((res) => {
    setPhotoLoading(item.id, false);
    setPhoto(item.id, res.data.value.data);
  })
  .catch((err) => {
    setPhotoLoading(item.id, false);
    $popup.error('Error rotate photo');
  })
}



const loadData = async (id) => {
  let data = await $api.getDiaryHarvestNutrientsReview(id);  
  is_loaded = true;
  return data;
}


// const { pending: is_loading,  data: nutrients_data } = await useLazyAsyncData('nutrients_data', async () => await loadData(props.diary.id))





watch(() => props.week, (new_props_week, old_props_week) => {
  edited_week.value = new_props_week;
}, {deep: true});




 
</script>

<style scoped>

  .parameters{
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
 

  .photos{
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap:15px;
    -webkit-user-select: none; 
    -ms-user-select: none; 
    user-select: none;
  }
  .photos_ghost{
  opacity: 0.5;
  background: #bee85d;
  }
  .udr_bx{
    text-align: center;
    margin: 1rem;
  }

.slid{
  max-width: 600px;
  margin: 0 auto;
}

</style>
